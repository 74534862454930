import PropTypes from 'prop-types'
import React from 'react';
import pic01 from '../images/1.png'
import pic09 from '../images/9.png'
import pic12 from '../images/12.png'
import pic13 from '../images/13.png'

class Main extends React.Component {
  constructor() {
    super();
    this.state = {
      submitted: false,
      message: '',
      name: '',
      email: '',
    };

    this.onClick = this.onClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    
  }

  async onClick(e) {
    this.setState({ submitted: true });
    e.preventDefault();
    e.stopPropagation();
    try {
    console.log('trying to send email')
    const emailToLex = await fetch("/.netlify/functions/sendEmail", {
      method: "POST",
      body:  JSON.stringify(this.state)
    })

    console.log('Email Form Component - Success: \n')
      return {
        studio: emailToLex,
      }
    } catch(e) {
      console.error('Error: ', e)
    }
  }

  handleChange (evt) {
    this.setState({ [evt.target.name]: evt.target.value });
  }

  render() {
    console.log({state: this.state})
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )
    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>          
          <span>
            <iframe
            className="image main" allowFullScreen
              src="https://www.youtube.com/embed/9OIub25cXE4">
            </iframe>
          </span>
          <p>
            One of my teachers once told me that it doesn’t matter what brings you to the mat, 
            it is the fact that you have got there and you are prepared to let yoga reveal itself to you 
            that is important. I found yoga at a point in my life where a lot of things were out of 
            balance and I was sceptical as to whether it would help or not. I have been amazed how 
            much this ancient practice has helped me to change; I am calmer, stronger more focused 
            and most importantly happy and content.
          </p>
          <p>
            I am a 200HR RYT and am passionate about helping people to create and hold space for themselves. 
            To work on that internal connection that allows us to truly be present and connect with other people. 
            My classes work on building mental and physical strength. Creating and holding space for each 
            individual to work on internal connection with gratitude and compassion. I accommodate all 
            abilities offering both challenges and support, welcoming all with an open heart and mind.
          </p>
          <p>
            As well as teaching yoga I am a MSDT scuba instructor with a passion for travel, hatred of 
            wearing foot prisons (shoes), avid foodie and when not on my mat you will probably find me either 
            up a hill somewhere with my dogs or out in the ocean blowing bubbles.
          </p>
          {close}
        </article>

        <article
          id="privateClasses"
          className={`${this.props.article === 'privateClasses' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Private Classes</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Whether it is getting to grips with the fundamentals of yoga, deepening your practice or 
            working on healing an injury, 1-2-1’s are a great opportunity to work together on your 
            aims for practice. I offer private sessions either in the comfort of your own home or 
            I can arrange for studio space if you would prefer. I like to work collaboratively with my 
            clients to make you as comfortable as possible as we explore not just the physical but 
            the mental and spiritual elements of yoga. Private sessions can be booked for you individually, 
            in small groups or even as corporate sessions if you are looking to bring some mindfulness 
            into your workplace. 
          </p>
          <p>
            While COVID-19 restrictions are still in place I am able to offer in-person private sessions 
            as long as they are conducted outside and social distancing measures are adhered to. 
            Online private sessions via zoom are still available. 
          </p>
          <span className="image main">
            <img src={pic09} alt="" />
          </span>
          <p>
            As well as offering tuition in asana practice I also teach dedicated meditation 
            and pranayama sessions. Meditation in and of itself is an incredibly powerful tool, 
            but one that often seems unattainable with people feeling like they either ‘can’t stop thinking’ 
            or are ‘bad’ at it. I like to work with individuals and groups of demystifying the concepts 
            around meditation, making it accessible and more importantly a practice that you can 
            confidently incorporate into your daily life. 
          </p>
          <p>
            Please contact me to have a chat about how we can flow together.
          </p>
          {close}
        </article>

        <article
          id="schedule"
          className={`${this.props.article === 'schedule' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Class Schedule</h2>
          <span className="image main">
            <img src={pic13} alt="" />
          </span>
          <p>
            You can flow with me here:
          </p>
            <br/>
            <table>
              <tbody>
              <tr>
                <td><b>When</b></td>
                <td><b>Practice</b></td>
                <td><b>Where</b></td>
                <td><b>How</b></td>
              </tr>
              <tr>
                <td>Tuesdays<br/>6pm</td>
                <td>Recovery Yoga</td>
                <td>Zoom</td>
                <td>Book here</td>
              </tr>
              <tr>
                <td>Thursdays<br/>7pm</td>
                <td>Flow Yoga</td>
                <td>Narali Yoga <br/>(currently online)</td>
                <td>Narali App</td>
              </tr>
              <tr>
                <td>Saturdays<br/>10am</td>
                <td>Slow Flow</td>
                <td>Narali Yoga <br/>(currently online)</td>
                <td>Narali App</td>
              </tr>
              </tbody>
            </table>
            <br/>
            <p>
              <b>Tuesdays: 6pm - Recovery yoga<br/>Dedicated 12 step focused session. Members of all fellowships are welcome. Live streamed via Zoom</b> 
            </p>
            <p>
              This is a one hour class starting with breath work and guided meditation then moving through a flow centred on the principles of recovery.
              This class is a place where you can find your breath, learn how to move your body in new ways, and experience self-awareness and self-understanding. 
              As well as movement it focuses on the mental practice of letting go and drawing attention to the breath. 
              Class will always end with an indulgent shavassana (relaxation posture) and meditation leaving you feeling calm, focused and grounded. 
              Payment for the class will be completely discretionary and a limit set to a maximum donation of £5. 25% of all monies taken will be split between 
              two charities;<br/>
              Back on Track Manchester & MASH
            </p>
            
            <br/>
            <p>
              <b>Thursday: 7pm - Flow Yoga<br/>Narali Yoga Studio - Book through the Narali Yoga App & Live streamed via zoom.</b>
            </p>
            <p>
              This class focuses on restoring equilibrium and encourages you to find your true potential. This can be done through stronger more dynamic practices 
              or by encouraging practitioners to slow down holding postures and focusing on becoming still. There is always one common thread though and this is 
              to be present, listening to our bodies. All practices incorporate breathing and relaxation techniques to help focus the mind and connect with the body, 
              thus creating inner harmony and a greater sense of wellbeing.
            </p>
            <br/>
            <p>
              <b>Saturday: 10am Slow Flow<br/>Narali Yoga Studio - Book through the Narali Yoga App & live streamed via zoom. </b>
            </p>
            <p>
              This class is a place where you can find your breath, learn how to move your body in new ways, and experience self-awareness and self-understanding. 
              As well as movement it focuses on the mental practice of letting go and drawing attention to the breath. 
              From the outset I will be guide you through intention setting, meditation and finding the calm quiet space in which to move freely. 
              Physically the movement is yin-inspired with elements of restorative and Hatha. Working on deep-tissue release this class will help to 
              build flexibility and restore range of motion.
            </p>
          {close}
        </article>

        <article
          id="retreats"
          className={`${this.props.article === 'retreats' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Retreats</h2>
          <span className="image main">
            <img src={pic12} alt="" />
          </span>
          <p>
            I work in partnership with Narali Yoga to deliver yoga and wellness retreats in various locations globally. 
            Following the break out of COVID-19 we have moved our scheduled 2020 retreats to 2021.
          </p>
          <b/>
          <p>
            <b>7th -12th June 2021</b><br/>
            <b>Mykonos, Greece</b><br/>
            <b>Yoga – Fitness – Wellness</b><br/>
          </p>
          <p>
          For this bespoke retreat we are bringing together yoga and fitness training to offer a complete wellness package. 
          Secluded in our luxurious villa on the Island of Mykonos, we will spend five days cleansing, toning and rejuvenating the body and mind.
          </p>
            <p><b>Highlights</b></p>
          <p>
            <span>- Up to 6 hours of yoga and fitness classes per day (Some classes are optional)</span><br/>
            <span>- Delicious organic food prepared by our private chef</span><br/>
            <span>- Personal fitness/practice goal setting</span><br/>
            <span>- Included: one 30-minute Facial; option of either rejuvenating or anti-aging facial</span><br/>
            <span>- Included: one sixty-minute deep tissue massage</span><br/>
            <span>- Excursion to explore the beautiful Mykonos coastline</span><br/>
            <span>- Final evening celebration meal</span><br/>
            <br/>
            <span>- Prices from £1500 - £2000 (Excluding flights)*</span><br/>
            <span>*Prices subject to confirmation. Prices may vary based on room occupancy</span><br/>
          </p>
          <br/>
          <span>For information please contact me here.....</span>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          {
          !this.state.submitted
          ? <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" onChange={this.handleChange}/>
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" onChange={this.handleChange}/>
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4" onChange={this.handleChange}></textarea>
            </div>
            <ul className="actions">
              <li>
                <button type="button" onClick={this.onClick}>
                  Submit
                </button>
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form> 
          : <div><span>Thanks for your enquiry, I will be in touch with you soon</span><br/></div>
          }
          <br/>
          <ul className="icons">
            <li>
              <a href="https:/facebook.com/lexsyoga" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://instagram.com/lexsyoga" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
